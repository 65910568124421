<template>
    <div>
        <Tabs v-model="tabsValue" @on-click="tabsClick">
            <TabPane label="待审核" name="name1"></TabPane>
            <TabPane label="待打款" name="name2"></TabPane>
            <TabPane label="已完成" name="name3"></TabPane>
            <!-- <TabPane label="已失败" name="name4"></TabPane> -->
        </Tabs>
        <searchBody>
            <search class="searchbar" :show-create="false" :label-width="80" :searchs="searchs" @search="onSearch" @reset="reset" @create="onEdit()"></search>
            <div style="display: flex; position: relative; left: 90%; bottom: 8px">
                <Button type="primary" ghost @click="importData">导入</Button>
                <Button type="primary" ghost @click="Export" style="margin-left: 5px">导出</Button>
            </div>
            <Card class="table_container" ref="table_container">
                <Table :key="tableKey" stripe class="table mt_10" :columns="dataColumn" :data="dataList">
                    <template slot-scope="{ row }" slot="process">
                        <span :class="'now_state_' + row.process" v-if="row.process_status == 1">已审核</span>
                        <span :class="'now_state_' + row.process" v-if="row.process_status == 2">已驳回</span>
                        <span :class="'now_state_' + row.process" v-if="row.process_status == 0">待审核</span>
                    </template>
                    <template slot-scope="{ row }" slot="type">
                        <span v-if="row.type == 1">省代理</span>
                    </template>
                    <template slot-scope="{ row }" slot="manager">
                        <div class="table_manager flex flex_wrap align_center">
                            <!-- <Button type="success" size="small" ghost @click="onView(row)">详情</Button> -->
                            <Button type="primary" size="small" ghost @click="onPause(row)">通过</Button>
                            <Button type="primary" size="small" ghost @click="onbh(row)">驳回</Button>
                        </div>
                    </template>
                </Table>
            </Card>
            <div class="relative width_100_percent text_align_right">
                <Page size="small" :total="totalPageNum || 1" :page-size="1" show-elevator @on-change="currentChange" />
            </div>
            <Modal :closable="false" v-model="showbh" title="驳回" width="500" :loading="ajaxLoading">
                <p>您是否确认通过驳回？</p>
                <div slot="footer" class="relative width_100_percent flex align_center justify_center">
                    <Button type="primary" ghost @click="onCancelbh">取消</Button>
                    <Button class="ml_100" type="primary" :loading="ajaxLoading" @click="onConfirmbh">确定</Button>
                </div>
            </Modal>
            <Modal v-model="ztmodal" title="审核" :loading="loading" @on-ok="sure">
                <p>您是否确认通过审核？</p>
            </Modal>
            <Modal v-model="importmodal" title="导入" :loading="loading" @on-ok="sureImport">
                <!-- <input type="file" @change="handleFileImport" /> -->
                <Upload  :on-success="handleSuccess" action="/web/finance/wdImport">
                    <Button icon="ios-cloud-upload-outline">请上传文件</Button>
                </Upload>
                <div v-if="file !== null">
                    Upload file: {{ file.name }}
                    <Button type="text" @click="upload" :loading="loadingStatus">{{ loadingStatus ? 'Uploading' : 'Click to upload' }}</Button>
                </div>
            </Modal>
            <mySpin :loading="ajaxLoading"></mySpin>
        </searchBody>
    </div>
</template>
<script>
import pageMixins from '@/lib/pageMixins';
import tableSearch from '@/components/tableSearch';
import searchBody from '@/components/searchBody';
import { reqMerchant } from '@/lib/request/auth2';
import { getAgentList, modPasswode, deleteItem, cancel, processSub, massList, getWdLogsList, wdCheck, wdImport } from '@/lib/request/auth';
import { yjExport } from '@/lib/request/auth2';
import UploadImage from '@/components/uploadImage';
import { consoleJson, isNotNullObject } from '@/lib/util';

export default {
    name: 'serviceProviderList',
    mixins: [pageMixins],
    components: {
        search: tableSearch,
        searchBody,
        UploadImage,
    },
    data() {
        return {
            file: null,
            loadingStatus: false,
            tabsValue: 'name1',
            remark: '',
            showbh: false,
            value: '',
            treedata: [
                {
                    title: 'parent1',
                    expand: true,
                    value: 'parent1',
                    selected: false,
                    checked: false,
                    children: [
                        {
                            title: 'parent 1-1',
                            expand: true,
                            value: 'parent1-1',
                            selected: false,
                            checked: false,
                            children: [
                                {
                                    title: 'leaf 1-1-1',
                                    value: 'leaf1',
                                    selected: false,
                                    checked: false,
                                },
                                {
                                    title: 'leaf 1-1-2',
                                    value: 'leaf2',
                                    selected: false,
                                    checked: false,
                                },
                            ],
                        },
                        {
                            title: 'parent 1-2',
                            expand: true,
                            value: 'parent1-2',
                            selected: false,
                            checked: false,
                            children: [
                                {
                                    title: 'leaf 1-2-1',
                                    value: 'leaf3',
                                    selected: false,
                                    checked: false,
                                },
                                {
                                    title: 'leaf 1-2-1',
                                    value: 'leaf4',
                                    selected: false,
                                    checked: false,
                                },
                            ],
                        },
                    ],
                },
            ],
            ztname: '',
            ztid: '',
            ztmodal: false,
            deleteid: '',
            loading: false,
            modal: false,
            formData: {
                id: '',
            },
            title: '',
            showCreate: false,
            searchs: [
                // { id: hm.createId(), label: '身份ID', placeholder: '请输入身份ID', type: 'input', bind: 'id_card_no' },
                { id: hm.createId(), label: '提现人', placeholder: '请输入提现人', type: 'input', bind: 'au_name' },
                { id: hm.createId(), label: '人员ID', placeholder: '请输入人员ID', type: 'input', bind: 'au_id_card_no' },
                { id: hm.createId(), label: '提现单号', placeholder: '请输入人员ID', type: 'input', bind: 'bank_no' },
                {
                    id: hm.createId(),
                    label: '主体',
                    placeholder: '选择主体',
                    type: 'select',
                    bind: 'process_status',
                    list: [
                        { id: '4', title: '小B端' },
                        { id: '3', title: '个人' },
                        { id: '2', title: '经销商' },
                        { id: '1', title: '省代理' },
                    ],
                },
            ],
            // searchs: [{ id: hm.createId(), label: '关键词', placeholder: '输入关键词', type: 'input', bind: 'name' }],
            tableKey: 'tableKey',
            dataColumn: [
                { key: 'id', title: '编号' },
                { key: 'au_name', title: '提现人' },
                { key: 'au_id_card_no', title: '人员ID' },
                { key: 'au_type_text', title: '账户类型' },
                { key: 'created_at', title: '申请时间' },
                { key: 'au_phone', title: '联系方式' },
                { key: 'wd_sn', title: '提现单号' },
                { key: 'amount', title: '提现金额' },
                { key: 'rate_amount', title: '提现手续费用' },
                { key: 'au_bank_code', title: '提现银行' },
                { key: 'au_bank_branch', title: '所属支行' },
                { key: 'bank_sn', title: '银行回单' },
                { key: 'au_bank_acount', title: '银行卡号' },
                { slot: 'manager', title: '操作' },
            ],
            query: {
                page: 1,
                limit: 10,
                pid: 0,
            },
            dataList: [],
            tabFlag: false,
            importmodal: false,
            arrPic: [],
        };
    },
    mounted() {
        this.params.status = 0;
        this.getList();
    },
    methods: {
        handleSuccess(res, file) {
            this.fadeAlert('导入成功');
            this.getList();
        console.log('File uploaded successfully', file);
        },
        handleUpload(file) {
            
            this.file = file;
            console.log(this.file,'789')
            return false;
        },
        upload() {
            this.loadingStatus = true;
            setTimeout(() => {
                this.file = null;
                this.loadingStatus = false;
                this.$Message.success('Success');
            }, 1500);
        },
        //单个图片上传成功
        onSuccessUploadImg() {
            if (this.arrPic.length < this.maxLength && hm.isNotEmptyString(this.arrPic.slice(-1)[0])) {
                this.arrPic.push('');
            } else if (this.arrPic.length <= 0) {
                this.arrPic.push('');
            }
        },
        //删除某张图片
        onRemoveImg() {
            this.arrPic = this.arrPic.filter(src => !!src);
            this.onSuccessUploadImg();
            this.arrPic.push(0);
            this.arrPic.pop();
        },
        sureImport() {
            // this.getList();
        },
        handleFileImport(event) {
            console.log(event.target, ' event.target');
            this.file = event.target.files[0];
            console.log(this.file, 'this.file');
        },
        importData() {
            this.importmodal = true;
            // if (!this.file) {
            //     alert('请选择一个文件进行导入。');
            //     return;
            // }
            // const reader = new FileReader();
            // reader.onload = e => {
            //     const data = e.target.result;
            //     // 这里处理导入的数据，例如将数据传递到Vuex store或者应用状态
            //     console.log(data);
            // };
            // reader.onerror = function (e) {
            //     console.error('文件读取出错:', e);
            // };
            // console.log(this.file, '47741');
            // reader.readAsText(this.file);
        },
        Export() {
            let params = window.structuredClone(this.params);
            params.export = 1;
            yjExport(params);
        },
        Import() {},
        //切换
        tabsClick(name) {
            console.log(name, 'name');
            if (name == 'name2') {
                this.params.status = 1;
                this.getList();
                if (this.tabFlag) {
                    this.dataColumn.splice(-3, 3);
                } else {
                    this.dataColumn.splice(this.dataColumn.length - 1, 1);
                }
                this.dataColumn.push({ key: 'phone', title: '审核时间' });
                this.tabFlag = false;
            } else if (name == 'name1') {
                this.params.status = 0;
                this.getList();
                if (this.tabFlag) {
                    this.dataColumn.splice(-3, 3);
                } else {
                    this.dataColumn.splice(this.dataColumn.length - 1, 1);
                }
                this.dataColumn.push({ slot: 'manager', title: '操作' });
                this.tabFlag = false;
            } else if (name == 'name3') {
                this.params.status = 2;
                this.getList();
                if (this.tabFlag) {
                    this.dataColumn.splice(-3, 3);
                } else {
                    this.dataColumn.splice(this.dataColumn.length - 1, 1);
                }
                this.dataColumn.push({ key: 'phone', title: '打款时间' });
                this.tabFlag = false;
            } else if (name == 'name4') {
                this.dataColumn.splice(this.dataColumn.length - 1, 1);
                this.dataColumn.push({ key: 'phone', title: '失败原因' }, { key: 'phone', title: '时间原因' }, { key: 'phone', title: '驳回原因' });
                this.tabFlag = true;
            }
        },
        onCancelbh() {
            this.showbh = false;
            this.remark = '';
        },
        onConfirmbh() {
            wdCheck({ id: this.deleteid, status: -1 }).then(res => {
                this.fadeAlert('操作成功');
                this.showbh = false;
                this.getList();
            });
        },
        sure() {
            wdCheck({ id: this.ztid, status: 1 }).then(res => {
                this.fadeAlert('操作成功');
                this.ztmodal = false;
                this.getList();
            });
        },
        onbh(row) {
            this.deleteid = row.id;
            this.showbh = true;
        },
        onPause(row) {
            this.ztid = row.id;
            // this.ztname = row.name;
            this.ztmodal = true;
        },
        onDelete(row) {
            this.modal = true;
            this.deleteid = row.id;
        },
        asyncOK() {
            deleteItem({ id: this.deleteid }).then(res => {
                this.fadeAlert('修改成功');
                this.modal = false;
                this.getList();
            });
        },
        onConfirmCreate() {
            this.showAjaxLoading();
            modPasswode(this.formData)
                .then(res => {
                    this.showCreate = false;
                    this.fadeAlert('修改成功');
                    this.formData = {};
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        showpassword(row) {
            this.formData.id = row.id;
            this.title = '修改密码';
            this.showCreate = true;
        },
        onCancelCreate() {
            this.showCreate = false;
        },
        //获取列表数据
        getList() {
            this.showAjaxLoading();
            getWdLogsList(this.params)
                .then(res => {
                    this.tableKey = hm.createId();
                    this.dataInjectDataList(res);
                    this.dataList = res.data.list;
                })
                .finally(() => {
                    this.hideAjaxLoading();
                });
        },
        //新建/编辑
        onEdit(_info = {}) {
            let info = window.structuredClone(_info);
            if (isNotNullObject(info)) {
                info.userTel = info.userPhone;
                info.address_code = !!info.address_code ? info.address_code.split(',') : [];
                info.address_note = !!info.address ? info.address.split(';')[1] : null;
                delete info.userPhone;
            } else {
                info.create = 1;
            }
            // this.goName('distributionListAdd', info);
            this.$router.push({ path: '/distributionListAdd', query: { isEdit: true, id: info.id } });
        },
        onCreate() {
            this.$router.push({ path: '/distributionListAdd', query: { isEdit: false, isDetail: false } });
            // this.goName('distributionListAdd');
        },

        //详情
        onView(info = {}) {
            // let { id } = info || {};
            // this.goUrl('/serviceProviderView', { id });
            this.$router.push({ path: '/distributionListAdd', query: { isEdit: true, id: info.id, isDetail: true, isProxy: 2 } });
        },
    },
};
</script>
<style lang="less" scoped>
.table_container {
    & .ivu-switch span {
        font-size: revert;
    }
}
.addbtn {
    position: absolute;
    top: 38px;
    right: 24px;
}
</style>
